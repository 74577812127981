import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as menue28QHjVRIcMeta } from "/vercel/path0/pages/menu.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: menue28QHjVRIcMeta?.name ?? "menu",
    path: menue28QHjVRIcMeta?.path ?? "/menu",
    meta: menue28QHjVRIcMeta || {},
    alias: menue28QHjVRIcMeta?.alias || [],
    redirect: menue28QHjVRIcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/menu.vue").then(m => m.default || m)
  }
]